import React, { useContext } from "react";
import { Experience } from "../../entities/experience";
import { AppStateContext } from "../../state-management/app-state-context";
import { ErrorPanel } from "../common-ui/error-panel/error-panel";
import { Grid } from "../common-ui/grid/grid";
import { LoadingSpinner } from "../common-ui/loading-spinner/loading-spinner";
import { ExperienceCard } from "../experience-card/experience-card";

interface Props {
  experiences: Experience[];
}

function FeaturedExperiences(props: Props): JSX.Element {
  return (
    <Grid>
      {props.experiences.map((experience) => (
        <ExperienceCard experience={experience} key={experience.id} />
      ))}
    </Grid>
  );
}

export function StatefulFeaturedExperiences(): JSX.Element {
  const context = useContext(AppStateContext);
  const experiencesWithStatus = context.appState.featuredExperiences;
  switch (experiencesWithStatus.status) {
    case "ok":
      return <FeaturedExperiences experiences={experiencesWithStatus.data} />;
    case "loading":
      return <LoadingSpinner />;
    case "error":
      return <ErrorPanel />;
  }
}
