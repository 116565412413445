import React, { useContext } from "react";
import { AppStateContext } from "../../state-management/app-state-context";
import { TextField } from "../common-ui/form-components/text-field/text-field";

interface Props {
  onChange: (value: string) => void;
  value: string;
}

export function SearchField(props: Props): JSX.Element {
  return (
    <TextField
      placeholder="Search for..."
      value={props.value}
      onChange={props.onChange}
    />
  );
}

export function StatefulSearchField(): JSX.Element {
  const { appState, operations } = useContext(AppStateContext);

  return (
    <SearchField
      value={appState.searchQuery}
      onChange={operations.setSearchQuery}
    />
  );
}
