import React, { PropsWithChildren } from "react";
import styles from "./ui-group.module.scss";
import cx from "classnames";

type Well = "vertical" | "horizontal" | "all" | "top" | "none";

interface Props {
  background: "light" | "transparent";
  well: Well;
}

export function UIGroup(props: PropsWithChildren<Props>): JSX.Element {
  const cssClasses = cx(wellCssClass(props.well), {
    [styles.light]: props.background === "light",
    [styles.transparent]: props.background === "transparent",
  });

  return <div className={cssClasses}>{props.children}</div>;
}

function wellCssClass(well: Well): string {
  switch (well) {
    case "all":
      return styles["well--all"];
    case "vertical":
      return styles["well--vertical"];
    case "horizontal":
      return styles["well--horizontal"];
    case "top":
      return styles["well--top"];
    case "none":
      return styles["well--none"];
  }
}

const defaultProps: Pick<Props, "background" | "well"> = {
  background: "transparent",
  well: "all",
};

UIGroup.defaultProps = defaultProps;
