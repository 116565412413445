import React from "react";
import { AppStateOperations, preConnectedOperations } from "./operations";
import { AppState, initialState } from "./state";

export const AppStateContext = React.createContext<{
  appState: AppState;
  operations: AppStateOperations;
}>({
  appState: initialState,
  operations: preConnectedOperations,
});
