import React from "react";
import styles from "./text-field.module.scss";

interface Props {
  value: string;
  placeholder?: string;
  onChange: (newValue: string) => void;
}

export function TextField(props: Props): JSX.Element {
  function onChangeWrapper(e: React.FormEvent<HTMLInputElement>) {
    const newValue = e.currentTarget.value;
    props.onChange(newValue);
  }
  return (
    <input
      className={styles.input}
      type="text"
      placeholder={props.placeholder}
      value={props.value}
      onChange={onChangeWrapper}
    />
  );
}
