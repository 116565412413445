import React from "react";
import { Experience } from "../../entities/experience";
import styles from "./experience-card.module.scss";

interface Props {
  experience: Experience;
}

export function ExperienceCard({ experience }: Props): JSX.Element {
  return (
    <div className={styles.container}>
      <img
        data-testid="thumbnail"
        className={styles.image}
        src={experience.imageUrl}
        alt={experience.title}
      />
      <div className={styles["info-container"]}>
        <div className={styles["title-label"]}>{experience.title}</div>
        <div className={styles["location-container"]}>
          <img
            className={styles["location-pin"]}
            src="/img/location-pin.svg"
            alt="Location pin"
            height="16px"
          />
          <div className={styles["location-label"]}>{experience.location}</div>
        </div>
      </div>
    </div>
  );
}
