import { Dispatch } from "react";
import {
  fetchFeaturedExperiences,
  fetchPopularNearbyExperiences,
} from "../api";
import { AppAction } from "./actions";

export type AppStateOperations = {
  setSearchQuery: (query: string) => void;
  loadFeaturedExperiences: () => Promise<void>;
  loadPopularNearbyExperiences: () => Promise<void>;
};

export function makeOperations(
  dispatch: Dispatch<AppAction>
): AppStateOperations {
  return {
    setSearchQuery: (query: string) => {
      dispatch({ type: "SET_SEARCH_QUERY", payload: { query } });
    },
    loadFeaturedExperiences: async () => {
      try {
        const experiences = await fetchFeaturedExperiences();
        dispatch({
          type: "SET_FEATURED_EXPERIENCES",
          payload: {
            experiencesWithStatus: { status: "ok", data: experiences },
          },
        });
      } catch {
        dispatch({
          type: "SET_FEATURED_EXPERIENCES",
          payload: { experiencesWithStatus: { status: "error" } },
        });
      }
    },
    loadPopularNearbyExperiences: async () => {
      try {
        const experiences = await fetchPopularNearbyExperiences();
        dispatch({
          type: "SET_POPULAR_NEARBY_EXPERIENCES",
          payload: {
            experiencesWithStatus: { status: "ok", data: experiences },
          },
        });
      } catch {
        dispatch({
          type: "SET_POPULAR_NEARBY_EXPERIENCES",
          payload: { experiencesWithStatus: { status: "error" } },
        });
      }
    },
  };
}

export const preConnectedOperations: AppStateOperations = {
  setSearchQuery: () => undefined,
  loadFeaturedExperiences: () => Promise.resolve(undefined),
  loadPopularNearbyExperiences: () => Promise.resolve(undefined),
};
