import React, { useContext, useMemo } from "react";
import { Experience } from "../../entities/experience";
import { filterExperiencesByTitle } from "../../entities/experience-helpers";
import { AppStateContext } from "../../state-management/app-state-context";
import { Carousel } from "../common-ui/carousel/carousel";
import { ErrorPanel } from "../common-ui/error-panel/error-panel";
import { LoadingSpinner } from "../common-ui/loading-spinner/loading-spinner";
import { ExperienceCard } from "../experience-card/experience-card";
import styles from "./popular-experiences.module.scss";

interface Props {
  searchQuery: string;
  experiences: Experience[];
}

export function PopularExperiences(props: Props): JSX.Element {
  const { searchQuery, experiences } = props;

  const filteredExperiences = useMemo<Experience[]>(
    () => filterExperiencesByTitle(searchQuery, experiences),
    [searchQuery, experiences]
  );

  return (
    <div>
      {searchQuery !== "" && (
        <div className={styles["searching-for-label"]}>
          Results for{" "}
          <span className={styles["query-label"]}>
            &apos;{props.searchQuery}&apos;
          </span>
        </div>
      )}
      {filteredExperiences.length === 0 && (
        <div className={styles["no-results"]}>No results found</div>
      )}
      {filteredExperiences.length > 0 && (
        <Carousel>
          {filteredExperiences.map((experience) => (
            <ExperienceCard key={experience.id} experience={experience} />
          ))}
        </Carousel>
      )}
    </div>
  );
}

export function StatefulPopularExperiences(): JSX.Element {
  const context = useContext(AppStateContext);
  const experiencesWithStatus = context.appState.popularNearbyExperiences;
  switch (experiencesWithStatus.status) {
    case "loading":
      return <LoadingSpinner />;
    case "ok":
      return (
        <PopularExperiences
          searchQuery={context.appState.searchQuery}
          experiences={experiencesWithStatus.data}
        />
      );
    case "error":
      return <ErrorPanel />;
  }
}
