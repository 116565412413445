import React, { PropsWithChildren } from "react";
import { UIGroup } from "../common-ui/ui-group/ui-group";
import styles from "./main-content-container.module.scss";

export function MainContentContainer(
  props: PropsWithChildren<unknown>
): JSX.Element {
  return (
    <div className={styles.container}>
      <UIGroup well="horizontal">{props.children}</UIGroup>
    </div>
  );
}
