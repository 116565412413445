import { Experience } from "../entities/experience";

export type AppState = {
  searchQuery: string;
  popularNearbyExperiences: ExperiencesWithStatus;
  featuredExperiences: ExperiencesWithStatus;
};

export type ExperiencesWithStatus =
  | { status: "loading" }
  | { status: "ok"; data: Experience[] }
  | { status: "error" };

export const initialState: AppState = {
  searchQuery: "",
  popularNearbyExperiences: { status: "loading" },
  featuredExperiences: { status: "loading" },
};
