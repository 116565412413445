import React, { useContext, useEffect } from "react";
import { Heading } from "../../components/common-ui/heading/heading";
import { UIGroup } from "../../components/common-ui/ui-group/ui-group";
import { StatefulFeaturedExperiences } from "../../components/featured-experiences/featured-experiences";
import { StatefulPopularExperiences } from "../../components/popular-experiences/popular-experiences";
import { ActionPanel } from "../../components/action-panel/action-panel";
import { AppStateContext } from "../../state-management/app-state-context";

interface Props {
  loadFeaturedExperiences: () => void;
  loadPopularNearbyExperiences: () => void;
}

export function ExperiencesPage(props: Props): JSX.Element {
  useEffect(() => {
    props.loadFeaturedExperiences();
    props.loadPopularNearbyExperiences();
  }, []);

  return (
    <>
      <ActionPanel />
      <UIGroup background="light">
        <Heading>Popular around you</Heading>
        <UIGroup well="vertical">
          <StatefulPopularExperiences />
        </UIGroup>
        <Heading>Featured</Heading>
        <UIGroup well="top">
          <StatefulFeaturedExperiences />
        </UIGroup>
      </UIGroup>
    </>
  );
}

export function StatefulExperiencesPage(): JSX.Element {
  const context = useContext(AppStateContext);

  return (
    <ExperiencesPage
      loadFeaturedExperiences={context.operations.loadFeaturedExperiences}
      loadPopularNearbyExperiences={
        context.operations.loadPopularNearbyExperiences
      }
    />
  );
}
