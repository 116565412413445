import React from "react";
import { Heading } from "../heading/heading";
import styles from "./error-panel.module.scss";

export function ErrorPanel(): JSX.Element {
  return (
    <div className={styles.container}>
      <Heading>Oh no!</Heading>
      <p>Something has gone wrong</p>
    </div>
  );
}
