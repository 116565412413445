import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import styles from "./carousel.module.scss";

export function Carousel(props: PropsWithChildren<unknown>): JSX.Element {
  const children = React.Children.toArray(props.children);
  const firstSlideRef = useRef<HTMLDivElement>(null);

  const [slideWidth, setSlideWidth] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = children.length;

  useEffect(() => {
    function onWindowResize() {
      const newWidth =
        firstSlideRef.current?.getBoundingClientRect().width || 0;
      setSlideWidth(newWidth);
    }

    setCurrentSlide(0);
    onWindowResize();

    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [totalSlides]);

  function nextSlide() {
    setCurrentSlide((oldValue) => {
      return Math.min(totalSlides - 1, oldValue + 1);
    });
  }

  function previousSlide() {
    setCurrentSlide((oldValue) => {
      return Math.max(oldValue - 1, 0);
    });
  }

  return (
    <div className={styles["outer-container"]}>
      {currentSlide > 0 && (
        <div className={styles["previous-button"]} onClick={previousSlide}>
          <img height="32px" src="/img/caret-left.svg" />
        </div>
      )}
      {currentSlide + 1 < totalSlides && (
        <div className={styles["next-button"]} onClick={nextSlide}>
          <img height="32px" src="/img/caret-right.svg" />
        </div>
      )}
      <div
        className={styles["inner-container"]}
        style={{ right: `${currentSlide * slideWidth}px` }}
      >
        {children.map((child, index) => {
          if (index === 0) {
            return (
              <div className={styles.slide} key={index} ref={firstSlideRef}>
                {child}
              </div>
            );
          }
          return (
            <div className={styles.slide} key={index}>
              {child}
            </div>
          );
        })}
      </div>
    </div>
  );
}
