import { Experience } from "./entities/experience";

const baseUrl = "https://demo3136867.mockable.io";

export async function fetchFeaturedExperiences(): Promise<Experience[]> {
  const result = await fetch(`${baseUrl}/featured`);
  /* Add artificial latency to show loading states */
  await delay(200);
  const json = await result.json();
  return json.data.map(mapJsonDataToExperience);
}

export async function fetchPopularNearbyExperiences(): Promise<Experience[]> {
  const result = await fetch(`${baseUrl}/carousel`);
  /* Add artificial latency to show loading states */
  await delay(200);
  const json = await result.json();
  return json.data.map(mapJsonDataToExperience);
}

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function mapJsonDataToExperience(
  data: Record<string, string>,
  index: number
): Experience {
  return {
    id: index + 1,
    title: data.title,
    location: data.location,
    imageUrl: data.img,
  };
}
