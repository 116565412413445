import React, { useReducer } from "react";
import { StatefulExperiencesPage } from "./pages/experiences-page/experiences-page";
import { MainContentContainer } from "./components/main-content-container/main-content-container";
import { reducer } from "./state-management/reducer";
import { initialState } from "./state-management/state";
import { makeOperations } from "./state-management/operations";
import { AppStateContext } from "./state-management/app-state-context";

export function App(): JSX.Element {
  const [appState, dispatch] = useReducer(reducer, initialState);

  const operations = makeOperations(dispatch);

  return (
    <AppStateContext.Provider value={{ appState, operations }}>
      <MainContentContainer>
        <StatefulExperiencesPage />
      </MainContentContainer>
    </AppStateContext.Provider>
  );
}
