import React from "react";
import { StatefulSearchField } from "../search-field/search-field";
import { UIGroup } from "../common-ui/ui-group/ui-group";
import styles from "./action-panel.module.scss";

export function ActionPanel(): JSX.Element {
  return (
    <UIGroup well="vertical">
      <div className={styles["search-container"]}>
        <StatefulSearchField />
      </div>
    </UIGroup>
  );
}
