import { AppAction } from "./actions";
import { AppState } from "./state";

export function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case "SET_SEARCH_QUERY":
      return { ...state, searchQuery: action.payload.query };
    case "SET_FEATURED_EXPERIENCES":
      return {
        ...state,
        featuredExperiences: action.payload.experiencesWithStatus,
      };

    case "SET_POPULAR_NEARBY_EXPERIENCES":
      return {
        ...state,
        popularNearbyExperiences: action.payload.experiencesWithStatus,
      };
  }
}
