import { Experience } from "./experience";

export function filterExperiencesByTitle(
  query: string,
  experiences: Experience[]
): Experience[] {
  if (query === "") {
    return experiences;
  }
  return experiences.filter(
    (experience) =>
      experience.title.toUpperCase().indexOf(query.toUpperCase()) >= 0
  );
}
